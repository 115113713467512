import ls from "local-storage"
import { Link, navigate } from "gatsby"
import React, { useState, useEffect } from "react"
import Cookies from 'universal-cookie';

const removeCheckoutData = ({ data }) => {
  const site = (data || {}).site
  // const cookies = new Cookies();
  // cookies.set('cart', 'yes')
  // console.log(cookies.get('cart'));

 // ls.remove("lastRechargeCheckoutID")
  //ls.remove("lastCheckoutID")
  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <></>
    )
}
export default removeCheckoutData

export const query = graphql`
  query removeCheckoutDataQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SiteSettings
    }
  }
`